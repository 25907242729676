// import WebEn from './webEn';
// import WebEs from './webEs';
// import WebLatin from './WebLatin.js';
// import WebPt from './WebPt';
// import webPT_BR from './webPT_BR';

const WebEn = {
  "main": "Psychic Advice",
  "sub_main_heading": "Tarot & Astrology Readings",
  "Reviews": "Reviews",
  "main_": {
    "limited_text": "Limited offer for new clients!",
    "offer": "Start a Free Chat Now! <b>Pay just $0.79/min</b> when you are confident."
  },
  "two-content-block": {
    "heading": "Choose a Psychic Advisor & Start Free Chat",
    "_p": ``,
    "list": {
      "_1": "Affordable",
      "_2": "Professional",
      "_3": "Anytime",
      "_4": "Anywhere",
    },
    "_sub": "Ready to start feeling better today?"
  },
  "three-content-block": {
    "heading": "Why PsychicBook?",
    "_sub": "Best Experts",
    "_p": "We carefully select all of our experts. They take a test assessing their psychic abilities and qualification. We provide you with only those experts who have many years of experience and numerous customer reviews. It's a completely new level of psychic advice! Only highly personalized advice and detailed readings!"
  },
  "four-content-block": {
    "_sub": "True Advisor's Feedbacks",
    "_p": "Each of our psychic mediums has their own review section. We closely monitor the reviews and prevent scams. We value every customer's opinion and try to help you make the best choice!"
  },
  "five-content-block": {
    "_sub": "Customer Support & Quality Guarantee",
    "_p": "It is important for us to leave our customers satisfied, theefore our Customer Support works 24/7 and makes sure that your communication with an expert is anonymous and absolutely safe."
  },
  "six-content-block": {
    "head": "Our Packages",
    "_sub": "Tell about yourself and book a session with psychic",
    "_p": "",
    "_text": "* Offer available to new customers ONLY. 10 minutes purchase required."
  },
  "section-last": {
    "heading": "Download PsyсhicBook Now",
    "_sub": "Text yourself a link to download PsychicBook",
    "text_qr": 'Point your Phone Camera',
    "or": 'Or'
  },
  "footer": {
    "head": "2017 PsychicBook APP. All rights reserved.",
    "text": "<strong>Emergency Resources.</strong> If you are currently experiencing a life threatening situation or an emergency like thoughts of self harm or harming othes please use <a href=/help-en.html>these resources</a> to get immediate help or go to your nearest hospital."
  },
  "help": {
    "emergency": "Emergency Resources",
    "head": "Get help now",
    "description": "If you are currently experiencing a life threatening situation or an emergency like thoughts of self harm or harming othes please use these resources to get immediate help or go to your nearest hospital:"
  },
  "buttons": {
    "get_started": "Get Started",
    "send": "Send",
    "strtChat": "Start Free Chat"
  },
  "language": {
    "es": "Español",
    "en": "English",
    "pt": "Português"
  },
  "links": {
    "join_us": "Psychic? Join Us",
    "contact_us": "Contact US",
    "faq": "FAQ",
    "privacy": "Privacy Policy",
    "terms": "Terms of Use",
    "terms-user": "Terms of Use",
    "become": "Become a Psychic",
    "t_t": "Terms of Use for psychic"
  },
  "contact_us": {
    "header": "How can we help?",
    "sub-header": "If thee’s something we can do, let us know.",
    "header-form": "Submit a request",
    "email": "Your email address",
    "send": "Send",
    "subject": "Subject",
    "textarea": "Please enter the details of your request",
    "copycats": "A member of our support staff will respond as soon as possible",
    "success": "Thank you!",
    "error": "Error!!!",
    "success-message": "We appreciate that you’ve taken the time to write us. We’ll get back to you very soon. Have a great day. PsychicBook Support Team.",
    "error-message": "Your email wasn't send",
    "ok": "Ok, I understood",
    "attach": "Add file"
  },
  "info-messages": {
    "coming_IOS": "Our iOS App is almost ready to launch.",
    "coming_Android": "Our Android App is almost ready to launch.",
    "coming": "Coming soon",
    "sorry": "Stay Tuned"
  },
  "validation": {
    "required": "This field is required.",
    "email": "Please enter a valid email address."
  },
  people: {
    Michelle: {
      name: 'Michelle',
      status: 'Online',
      specialization: 'Love & Relationship',
      image: './public/images/psychic/two-section/man-1.png',
      count_stars: 5,
      ranking: '4.8',
      reviews: '17 Reviews',
      description: 'Michelle Starr prepares and analyzes horoscope to advise you regarding future trends and events.'
    },
    Harry: {
      name: 'Harry',
      status: 'Online',
      specialization: 'Live Questions',
      image: './public/images/psychic/two-section/man-2.png',
      count_stars: 5,
      ranking: '4.5',
      reviews: '25 Reviews',
      description: 'Psychic Harry Wand aims at bringing a positive outlook to your life and helps to see things from a different perspective. Harry is an inspirational and compassionate reader who often reads for clients who feel overwhelmed by their complex emotions and flowing energy.'
    },
    Wolf: {
      name: 'Wolf',
      status: 'Online',
      specialization: 'Psychic Readings',
      image: './public/images/psychic/two-section/man-3.png',
      count_stars: 5,
      ranking: '4.7',
      reviews: '49 Reviews',
      description: `I'm Clairvoyant and Clairaudient Medium. If you're ready for straightforward delivery rathe than receiving over-positive readings, welcome! I will help you find your own path through a realistic and direct approach: love life, career, all sort of issues, including financial, family, etc.`
    }
  }
};

const WebLatin = {
  "main": "Consejos Psíquicos",
  "sub_main_heading": "Lecturas de Tarot y Astrología",
  "Reviews": "Comentarios",
  "main_": {
    "limited_text": "Oferta limitada para nuevos clientes",
    "offer": "¡Comienza un chat gratuito ahora! <b>Pague solo 0.79 $ / min</b> cuando tenga confianza."
  },
  "two-content-block": {
    "heading": "Elige un Asesor Psíquico & Iniciar Chat Gratuito",
    "_p": ``,
    "list": {
      "_1": "Affordable",
      "_2": "Professional",
      "_3": "Anytime",
      "_4": "Anywhere",
    },
    "_sub": "Ready to start feeling better today?"
  },
  "three-content-block": {
    "heading": "¿Por qué psychicbook?",
    "_sub": "Los mejores Expertos",
    "_p": "Seleccionamos cuidadosamente a todos nuestros expertos. Toman una prueba que evalúa sus habilidades psíquicas y calificación. Le proporcionamos solo aquellos expertos que tienen muchos años de experiencia y numerosas opiniones de clientes. ¡Es un nivel completamente nuevo de consejo psíquico! ¡Solo consejos altamente personalizados y lecturas detalladas!"
  },
  "four-content-block": {
    "_sub": "Comentarios de True Advisor",
    "_p": "Cada uno de nuestros médiums psíquicos tiene su propia sección de revisión. Monitoreamos de cerca las revisiones y evitamos estafas. ¡Valoramos la opinión de cada cliente y tratamos de ayudarlo a tomar la mejor decisión!"
  },
  "five-content-block": {
    "_sub": "Atención al Cliente y Garantía de Calidad",
    "_p": "Es importante para nosotros dejar a nuestros clientes satisfechos, por lo tanto, nuestro servicio de atención al cliente funciona las 24 horas del día, los 7 días de la semana y se asegura de que su comunicación con un experto sea anónima y absolutamente segura."
  },
  "six-content-block": {
    "head": "Our Packages",
    "_sub": "Tell about yourself and book a session with psychic",
    "_p": "",
    "_text": "* Offer available to new customers ONLY. 10 minutes purchase required."
  },
  "section-last": {
    "heading": "Descargar PsyсhicBook Ahora",
    "_sub": "Text yourself a link to download PsychicBook",
    "text_qr": 'Apunta la cámara de tu teléfono',
    "or": 'O'
  },
  "footer": {
    "head": "2017 PsychicBook APP. All rights reserved.",
    "text": "<strong>Emergency Resources.</strong> If you are currently experiencing a life threatening situation or an emergency like thoughts of self harm or harming othes please use <a href=/help-en.html>these resources</a> to get immediate help or go to your nearest hospital."
  },
  "help": {
    "emergency": "Emergency Resources",
    "head": "Get help now",
    "description": "If you are currently experiencing a life threatening situation or an emergency like thoughts of self harm or harming othes please use these resources to get immediate help or go to your nearest hospital:"
  },
  "buttons": {
    "get_started": "Get Started",
    "send": "Send",
    "strtChat": "Iniciar Chat Gratuito"
  },
  "language": {
    "es": "Español",
    "en": "English",
    "pt": "Português"
  },
  "links": {
    "join_us": "Psychic? Join Us",
    "contact_us": "Contáctenos",
    "faq": "Preguntas más frecuentes",
    "privacy": "Política de Privacidad",
    "terms": "Condiciones de Uso",
    "terms-user": "Condiciones de Uso",
    "become": "Conviértete en un Psíquico",
    "t_t": "Terms of Use for psychic"
  },
  "contact_us": {
    "header": "How can we help?",
    "sub-header": "If thee’s something we can do, let us know.",
    "header-form": "Submit a request",
    "email": "Your email address",
    "send": "Send",
    "subject": "Subject",
    "textarea": "Please enter the details of your request",
    "copycats": "A member of our support staff will respond as soon as possible",
    "success": "Thank you!",
    "error": "Error!!!",
    "success-message": "We appreciate that you’ve taken the time to write us. We’ll get back to you very soon. Have a great day. PsychicBook Support Team.",
    "error-message": "Your email wasn't send",
    "ok": "Ok, I understood",
    "attach": "Add file"
  },
  "info-messages": {
    "coming_IOS": "Our iOS App is almost ready to launch.",
    "coming_Android": "Our Android App is almost ready to launch.",
    "coming": "Coming soon",
    "sorry": "Stay Tuned"
  },
  "validation": {
    "required": "This field is required.",
    "email": "Please enter a valid email address."
  },
  people: {
    Michelle: {
      name: 'Michelle',
      status: 'En línea',
      specialization: 'Love & Relationship',
      image: './public/images/psychic/two-section/man-1.png',
      count_stars: 5,
      ranking: '4.8',
      reviews: '17 Comentarios',
      description: 'Michelle Starr prepara y analiza el horóscopo para asesorarte sobre tendencias y eventos futuros.'
    },
    Harry: {
      name: 'Harry',
      status: 'En línea',
      specialization: 'Live Questions',
      image: './public/images/psychic/two-section/man-2.png',
      count_stars: 5,
      ranking: '4.5',
      reviews: '25 Comentarios',
      description: 'Psychic Harry Wand tiene como objetivo traer una perspectiva positiva a su vida y ayuda a ver las cosas desde una perspectiva diferente. Harry es un lector inspirador y compasivo que a menudo lee para clientes que se sienten abrumados por sus emociones complejas y su energía que fluye.'
    },
    Wolf: {
      name: 'Wolf',
      status: 'En línea',
      specialization: 'Psychic Readings',
      image: './public/images/psychic/two-section/man-3.png',
      count_stars: 5,
      ranking: '4.7',
      reviews: '49 Comentarios',
      description: `Soy Clarividente y Medio Clariaudiente. Si está listo para una entrega directa que para recibir lecturas demasiado positivas, ¡bienvenido! Te ayudaré a encontrar tu propio camino a través de un enfoque realista y directo: vida amorosa, carrera, todo tipo de cuestiones, incluidas las financieras, familiares, etc.`
    }
  }
};

const WebEs = {
  "main": "Consejos Psíquicos",
  "sub_main_heading": "Lecturas de Tarot y Astrología",
  "Reviews": "Comentarios",
  "main_": {
    "limited_text": "Oferta limitada para nuevos clientes",
    "offer": "¡Comienza un chat gratuito ahora! <b>Pague solo 0.79 $ / min</b> cuando tenga confianza."
  },
  "two-content-block": {
    "heading": "Elige un Asesor Psíquico & Iniciar Chat Gratuito",
    "_p": ``,
    "list": {
      "_1": "Affordable",
      "_2": "Professional",
      "_3": "Anytime",
      "_4": "Anywhere",
    },
    "_sub": "Ready to start feeling better today?"
  },
  "three-content-block": {
    "heading": "¿Por qué psychicbook?",
    "_sub": "Los mejores Expertos",
    "_p": "Seleccionamos cuidadosamente a todos nuestros expertos. Toman una prueba que evalúa sus habilidades psíquicas y calificación. Le proporcionamos solo aquellos expertos que tienen muchos años de experiencia y numerosas opiniones de clientes. ¡Es un nivel completamente nuevo de consejo psíquico! ¡Solo consejos altamente personalizados y lecturas detalladas!"
  },
  "four-content-block": {
    "_sub": "Comentarios de True Advisor",
    "_p": "Cada uno de nuestros médiums psíquicos tiene su propia sección de revisión. Monitoreamos de cerca las revisiones y evitamos estafas. ¡Valoramos la opinión de cada cliente y tratamos de ayudarlo a tomar la mejor decisión!"
  },
  "five-content-block": {
    "_sub": "Atención al Cliente y Garantía de Calidad",
    "_p": "Es importante para nosotros dejar a nuestros clientes satisfechos, por lo tanto, nuestro servicio de atención al cliente funciona las 24 horas del día, los 7 días de la semana y se asegura de que su comunicación con un experto sea anónima y absolutamente segura."
  },
  "six-content-block": {
    "head": "Our Packages",
    "_sub": "Tell about yourself and book a session with psychic",
    "_p": "",
    "_text": "* Offer available to new customers ONLY. 10 minutes purchase required."
  },
  "section-last": {
    "heading": "Descargar PsyсhicBook Ahora",
    "_sub": "Text yourself a link to download PsychicBook",
    "text_qr": 'Apunta la cámara de tu teléfono',
    "or": 'O'
  },
  "footer": {
    "head": "2017 PsychicBook APP. All rights reserved.",
    "text": "<strong>Emergency Resources.</strong> If you are currently experiencing a life threatening situation or an emergency like thoughts of self harm or harming othes please use <a href=/help-en.html>these resources</a> to get immediate help or go to your nearest hospital."
  },
  "help": {
    "emergency": "Emergency Resources",
    "head": "Get help now",
    "description": "If you are currently experiencing a life threatening situation or an emergency like thoughts of self harm or harming othes please use these resources to get immediate help or go to your nearest hospital:"
  },
  "buttons": {
    "get_started": "Get Started",
    "send": "Send",
    "strtChat": "Iniciar Chat Gratuito"
  },
  "language": {
    "es": "Español",
    "en": "English",
    "pt": "Português"
  },
  "links": {
    "join_us": "Psychic? Join Us",
    "contact_us": "Contáctenos",
    "faq": "Preguntas más frecuentes",
    "privacy": "Política de Privacidad",
    "terms": "Condiciones de Uso",
    "terms-user": "Condiciones de Uso",
    "become": "Conviértete en un Psíquico",
    "t_t": "Terms of Use for psychic"
  },
  "contact_us": {
    "header": "How can we help?",
    "sub-header": "If thee’s something we can do, let us know.",
    "header-form": "Submit a request",
    "email": "Your email address",
    "send": "Send",
    "subject": "Subject",
    "textarea": "Please enter the details of your request",
    "copycats": "A member of our support staff will respond as soon as possible",
    "success": "Thank you!",
    "error": "Error!!!",
    "success-message": "We appreciate that you’ve taken the time to write us. We’ll get back to you very soon. Have a great day. PsychicBook Support Team.",
    "error-message": "Your email wasn't send",
    "ok": "Ok, I understood",
    "attach": "Add file"
  },
  "info-messages": {
    "coming_IOS": "Our iOS App is almost ready to launch.",
    "coming_Android": "Our Android App is almost ready to launch.",
    "coming": "Coming soon",
    "sorry": "Stay Tuned"
  },
  "validation": {
    "required": "This field is required.",
    "email": "Please enter a valid email address."
  },
  people: {
    Michelle: {
      name: 'Michelle',
      status: 'En línea',
      specialization: 'Love & Relationship',
      image: './public/images/psychic/two-section/man-1.png',
      count_stars: 5,
      ranking: '4.8',
      reviews: '17 Comentarios',
      description: 'Michelle Starr prepara y analiza el horóscopo para asesorarte sobre tendencias y eventos futuros.'
    },
    Harry: {
      name: 'Harry',
      status: 'En línea',
      specialization: 'Live Questions',
      image: './public/images/psychic/two-section/man-2.png',
      count_stars: 5,
      ranking: '4.5',
      reviews: '25 Comentarios',
      description: 'Psychic Harry Wand tiene como objetivo traer una perspectiva positiva a su vida y ayuda a ver las cosas desde una perspectiva diferente. Harry es un lector inspirador y compasivo que a menudo lee para clientes que se sienten abrumados por sus emociones complejas y su energía que fluye.'
    },
    Wolf: {
      name: 'Wolf',
      status: 'En línea',
      specialization: 'Psychic Readings',
      image: './public/images/psychic/two-section/man-3.png',
      count_stars: 5,
      ranking: '4.7',
      reviews: '49 Comentarios',
      description: `Soy Clarividente y Medio Clariaudiente. Si está listo para una entrega directa que para recibir lecturas demasiado positivas, ¡bienvenido! Te ayudaré a encontrar tu propio camino a través de un enfoque realista y directo: vida amorosa, carrera, todo tipo de cuestiones, incluidas las financieras, familiares, etc.`
    }
  }
};

const WebPt = {
  "main": "Conselhos Psíquicos",
  "sub_main_heading": "Leituras de Tarot & Astrologia",
  "Reviews": "Comentários",
  "main_": {
    "limited_text": "Oferta limitada para novos clientes",
    "offer": "Comece uma conversa grátis agora! <b>Pague apenas 0,79$/min</b> quando estiver confiante."
  },
  "two-content-block": {
    "heading": "Escolha um Conselheiro Psíquico & Inicie o Chat Gratuito",
    "_p": ``,
    "list": {
      "_1": "Affordable",
      "_2": "Professional",
      "_3": "Anytime",
      "_4": "Anywhere",
    },
    "_sub": "Ready to start feeling better today?"
  },
  "three-content-block": {
    "heading": "Por que livro psíquico?",
    "_sub": "Melhores Especialistas",
    "_p": "Selecionamos cuidadosamente todos os nossos especialistas. Fazem um teste para avaliar as suas capacidades psíquicas e qualificação. Fornecemos-lhe apenas os especialistas que têm muitos anos de experiência e inúmeras avaliações de clientes. É um novo nível de conselhos psíquicos! Apenas conselhos altamente personalizados e leituras detalhadas!"
  },
  "four-content-block": {
    "_sub": "Comentários Verdadeiros do Conselheiro",
    "_p": "Cada um dos nossos meios psíquicos tem a sua própria secção de revisão. Acompanhamos de perto as análises e evitamos fraudes. Valorizamos a opinião de cada cliente e tentamos ajudá-lo a fazer a melhor escolha!"
  },
  "five-content-block": {
    "_sub": "Apoio ao Cliente e Garantia de Qualidade",
    "_p": "É importante para nós deixar os nossos clientes satisfeitos, o nosso Suporte ao Cliente funciona 24 horas por dia, 7 dias por semana, e garante que a sua comunicação com um especialista é anónima e absolutamente segura."
  },
  "six-content-block": {
    "head": "Our Packages",
    "_sub": "Tell about yourself and book a session with psychic",
    "_p": "",
    "_text": "* Offer available to new customers ONLY. 10 minutes purchase required."
  },
  "section-last": {
    "heading": "Baixar o PsyсhicBook Agora",
    "_sub": "Text yourself a link to download PsychicBook",
    "text_qr": 'Aponte a sua câmara de telefone',
    "or": 'Ou'
  },
  "footer": {
    "head": "2017 PsychicBook APP. All rights reserved.",
    "text": "<strong>Emergency Resources.</strong> If you are currently experiencing a life threatening situation or an emergency like thoughts of self harm or harming othes please use <a href=/help-en.html>these resources</a> to get immediate help or go to your nearest hospital."
  },
  "help": {
    "emergency": "Emergency Resources",
    "head": "Get help now",
    "description": "If you are currently experiencing a life threatening situation or an emergency like thoughts of self harm or harming othes please use these resources to get immediate help or go to your nearest hospital:"
  },
  "buttons": {
    "get_started": "Get Started",
    "send": "Send",
    "strtChat": "Iniciar Chat Gratuito"
  },
  "language": {
    "es": "Español",
    "en": "English",
    "pt": "Português"
  },
  "links": {
    "join_us": "Psychic? Join Us",
    "contact_us": "Contacte-nos",
    "faq": "Perguntas Frequentes",
    "privacy": "Política de Privacidade",
    "terms": "Termos de Utilização",
    "terms-user": "Termos de Utilização",
    "become": "Tornar-se um Psíquico",
    "t_t": "Terms of Use for psychic"
  },
  "contact_us": {
    "header": "How can we help?",
    "sub-header": "If thee’s something we can do, let us know.",
    "header-form": "Submit a request",
    "email": "Your email address",
    "send": "Send",
    "subject": "Subject",
    "textarea": "Please enter the details of your request",
    "copycats": "A member of our support staff will respond as soon as possible",
    "success": "Thank you!",
    "error": "Error!!!",
    "success-message": "We appreciate that you’ve taken the time to write us. We’ll get back to you very soon. Have a great day. PsychicBook Support Team.",
    "error-message": "Your email wasn't send",
    "ok": "Ok, I understood",
    "attach": "Add file"
  },
  "info-messages": {
    "coming_IOS": "Our iOS App is almost ready to launch.",
    "coming_Android": "Our Android App is almost ready to launch.",
    "coming": "Coming soon",
    "sorry": "Stay Tuned"
  },
  "validation": {
    "required": "This field is required.",
    "email": "Please enter a valid email address."
  },
  people: {
    Michelle: {
      name: 'Michelle',
      status: 'Online',
      specialization: 'Love & Relationship',
      image: './public/images/psychic/two-section/man-1.png',
      count_stars: 5,
      ranking: '4.8',
      reviews: '17 Comentários',
      description: 'Michelle Starr prepara e analisa o horóscopo para o aconselhar sobre tendências e eventos futuros.'
    },
    Harry: {
      name: 'Harry',
      status: 'Online',
      specialization: 'Live Questions',
      image: './public/images/psychic/two-section/man-2.png',
      count_stars: 5,
      ranking: '4.5',
      reviews: '25 Comentários',
      description: 'O psíquico Harry Wand pretende trazer uma perspetiva positiva à sua vida e ajuda a ver as coisas de uma perspetiva diferente. Harry é um leitor inspirador e compassivo que lê frequentemente para clientes que se sentem sobrecarregados com as suas emoções complexas e energia fluída.'
    },
    Wolf: {
      name: 'Wolf',
      status: 'Online',
      specialization: 'Psychic Readings',
      image: './public/images/psychic/two-section/man-3.png',
      count_stars: 5,
      ranking: '4.7',
      reviews: '49 Comentários',
      description: `Sou Clairvoyant e Clairaudient Medium. Se estiver pronto para uma entrega simples do que receber leituras excessivamente positivas, bem-vindo! Vou ajudá-lo a encontrar o seu próprio caminho através de uma abordagem realista e direta: vida amorosa, carreira, todo o tipo de questões, incluindo financeira, família, etc.`
    }
  }
};

const webPT_BR = {
  "main": "Conselhos Psíquicos",
  "sub_main_heading": "Leituras de Tarô & Astrologia",
  "Reviews": "Opiniões",
  "main_": {
    "limited_text": "Oferta limitada para novos clientes",
    "offer": "Comece um bate-papo grátis agora! <b>PPague apenas 0,79$/min</b> quando estiver confiante."
  },
  "two-content-block": {
    "heading": "Escolha um Conselheiro Psíquico & Iniciar o Bate-papo Gratuito",
    "_p": ``,
    "list": {
      "_1": "Affordable",
      "_2": "Professional",
      "_3": "Anytime",
      "_4": "Anywhere",
    },
    "_sub": "Ready to start feeling better today?"
  },
  "three-content-block": {
    "heading": "Por que livro psíquico?",
    "_sub": "Melhores Especialistas",
    "_p": "Selecionamos cuidadosamente todos os nossos especialistas. Eles fazem um teste avaliando suas habilidades psíquicas e qualificação. Nós fornecemos apenas aqueles especialistas que têm muitos anos de experiência e inúmeras avaliações de clientes. É um novo nível de conselhos psíquicos! Apenas conselhos altamente personalizados e leituras detalhadas!"
  },
  "four-content-block": {
    "_sub": "Comentários do Verdadeiro Conselheiro",
    "_p": "Cada um de nossos médiuns psíquicos tem sua própria seção de revisão. Monitoramos de perto as críticas e evitamos golpes. Valorizamos a opinião de cada cliente e tentamos ajudá-lo a fazer a melhor escolha!"
  },
  "five-content-block": {
    "_sub": "Suporte ao Cliente & Garantia de Qualidade",
    "_p": "É importante deixarmos nossos clientes satisfeitos, nossa assessoria ao cliente funciona 24 horas por dia, 7 dias por semana e garantir que sua comunicação com um especialista seja anônima e absolutamente segura."
  },
  "six-content-block": {
    "head": "Our Packages",
    "_sub": "Tell about yourself and book a session with psychic",
    "_p": "",
    "_text": "* Offer available to new customers ONLY. 10 minutes purchase required."
  },
  "section-last": {
    "heading": "Baixe PsyсhicBook Agora",
    "_sub": "Text yourself a link to download PsychicBook",
    "text_qr": 'Aponte sua câmera de telefone',
    "or": 'Ou'
  },
  "footer": {
    "head": "2017 PsychicBook APP. All rights reserved.",
    "text": "<strong>Emergency Resources.</strong> If you are currently experiencing a life threatening situation or an emergency like thoughts of self harm or harming othes please use <a href=/help-en.html>these resources</a> to get immediate help or go to your nearest hospital."
  },
  "help": {
    "emergency": "Emergency Resources",
    "head": "Get help now",
    "description": "If you are currently experiencing a life threatening situation or an emergency like thoughts of self harm or harming othes please use these resources to get immediate help or go to your nearest hospital:"
  },
  "buttons": {
    "get_started": "Get Started",
    "send": "Send",
    "strtChat": "Iniciar o Bate-papo Gratuito"
  },
  "language": {
    "es": "Español",
    "en": "English",
    "pt": "Português"
  },
  "links": {
    "join_us": "Psychic? Join Us",
    "contact_us": "Entre em contato conosco",
    "faq": "Perguntas Freqüentes",
    "privacy": "Política de Privacidade",
    "terms": "Termos de Uso",
    "terms-user": "Termos de Uso",
    "become": "Tornar-se um Psíquico",
    "t_t": "Terms of Use for psychic"
  },
  "contact_us": {
    "header": "How can we help?",
    "sub-header": "If thee’s something we can do, let us know.",
    "header-form": "Submit a request",
    "email": "Your email address",
    "send": "Send",
    "subject": "Subject",
    "textarea": "Please enter the details of your request",
    "copycats": "A member of our support staff will respond as soon as possible",
    "success": "Thank you!",
    "error": "Error!!!",
    "success-message": "We appreciate that you’ve taken the time to write us. We’ll get back to you very soon. Have a great day. PsychicBook Support Team.",
    "error-message": "Your email wasn't send",
    "ok": "Ok, I understood",
    "attach": "Add file"
  },
  "info-messages": {
    "coming_IOS": "Our iOS App is almost ready to launch.",
    "coming_Android": "Our Android App is almost ready to launch.",
    "coming": "Coming soon",
    "sorry": "Stay Tuned"
  },
  "validation": {
    "required": "This field is required.",
    "email": "Please enter a valid email address."
  },
  people: {
    Michelle: {
      name: 'Michelle',
      status: 'Online',
      specialization: 'Love & Relationship',
      image: './public/images/psychic/two-section/man-1.png',
      count_stars: 5,
      ranking: '4.8',
      reviews: '17 Opiniões',
      description: 'Michelle Starr prepara e analisa o horóscopo para aconselhá-lo sobre tendências e eventos futuros.'
    },
    Harry: {
      name: 'Harry',
      status: 'Online',
      specialization: 'Live Questions',
      image: './public/images/psychic/two-section/man-2.png',
      count_stars: 5,
      ranking: '4.5',
      reviews: '25 Opiniões',
      description: 'O psíquico Harry Wand tem como objetivo trazer uma visão positiva para sua vida e ajuda a ver as coisas de uma perspectiva diferente. Harry é um leitor inspirador e compassivo que costuma ler para clientes que se sentem sobrecarregados por suas emoções complexas e energia fluindo.'
    },
    Wolf: {
      name: 'Wolf',
      status: 'Online',
      specialization: 'Psychic Readings',
      image: './public/images/psychic/two-section/man-3.png',
      count_stars: 5,
      ranking: '4.7',
      reviews: '49 Opiniões',
      description: `Sou Clairvoyant e Clairaudient Medium. Se você está pronto para entrega direta do que receber leituras superpositivos, bem-vindo! Eu vou ajudá-lo a encontrar o seu próprio caminho através de uma abordagem realista e direta: vida amorosa, carreira, todos os tipos de questões, incluindo financeira, família, etc.`
    }
  }
};

export const arrayLanguages = {
    en: WebEn,
    es: WebEs,
    br: 'webPT_BR',
    pt: WebPt,
    lt: WebLatin,
    ptBr: webPT_BR,
    'es-AR': WebLatin,
    'es-BO': WebLatin,
    'es-CL': WebLatin,
    'es-CO': WebLatin,
    'es-EC': WebLatin,
    'es-PY': WebLatin,
    'es-PE': WebLatin,
    'es-UY': WebLatin,
    'es-VE': WebLatin,
    'pt-BR': webPT_BR,
    'pt-PT': WebPt,

    "es-GT": WebEs,
    "es-CR": WebEs,
    "es-PA": WebEs,
    "es-DO": WebEs,
    "es-MX": WebEs,
    "es-SV": WebEs,
    "es-HN": WebEs,
    "es-NI": WebEs, 
    "es-PR": WebEs
  };

  const navigatorJs = window.navigator.language;

  export const browserLocale = arrayLanguages[navigatorJs] || WebEn;