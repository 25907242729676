export default {
  "main": "Psychic Advice",
  "sub_main_heading": "Tarot & Astrology Readings",
  "Reviews": "Reviews",
  "main_": {
    "limited_text": "Limited offer for new clients!",
    "offer": "Start a Free Chat Now! <b>Pay just $0.79/min</b> when you are confident."
  },
  "two-content-block": {
    "heading": "Choose a Psychic Advisor & Start Free Chat",
    "_p": ``,
    "list": {
      "_1": "Affordable",
      "_2": "Professional",
      "_3": "Anytime",
      "_4": "Anywhere",
    },
    "_sub": "Ready to start feeling better today?"
  },
  "three-content-block": {
    "heading": "Why PsychicBook?",
    "_sub": "Best Experts",
    "_p": "We carefully select all of our experts. They take a test assessing their psychic abilities and qualification. We provide you with only those experts who have many years of experience and numerous customer reviews. It's a completely new level of psychic advice! Only highly personalized advice and detailed readings!"
  },
  "four-content-block": {
    "_sub": "True Advisor's Feedbacks",
    "_p": "Each of our psychic mediums has their own review section. We closely monitor the reviews and prevent scams. We value every customer's opinion and try to help you make the best choice!"
  },
  "five-content-block": {
    "_sub": "Customer Support & Quality Guarantee",
    "_p": "It is important for us to leave our customers satisfied, theefore our Customer Support works 24/7 and makes sure that your communication with an expert is anonymous and absolutely safe."
  },
  "six-content-block": {
    "head": "Our Packages",
    "_sub": "Tell about yourself and book a session with psychic",
    "_p": "",
    "_text": "* Offer available to new customers ONLY. 10 minutes purchase required."
  },
  "section-last": {
    "heading": "Download PsyсhicBook Now",
    "_sub": "Text yourself a link to download PsychicBook",
    "text_qr": 'Point your Phone Camera',
    "or": 'Or'
  },
  "footer": {
    "head": "2017 PsychicBook APP. All rights reserved.",
    "text": "<strong>Emergency Resources.</strong> If you are currently experiencing a life threatening situation or an emergency like thoughts of self harm or harming othes please use <a href=/help-en.html>these resources</a> to get immediate help or go to your nearest hospital."
  },
  "help": {
    "emergency": "Emergency Resources",
    "head": "Get help now",
    "description": "If you are currently experiencing a life threatening situation or an emergency like thoughts of self harm or harming othes please use these resources to get immediate help or go to your nearest hospital:"
  },
  "buttons": {
    "get_started": "Get Started",
    "send": "Send",
    "strtChat": "Start Free Chat"
  },
  "language": {
    "es": "Español",
    "en": "English",
    "pt": "Português"
  },
  "links": {
    "join_us": "Psychic? Join Us",
    "contact_us": "Contact US",
    "faq": "FAQ",
    "privacy": "Privacy Policy",
    "terms": "Terms of Use",
    "terms-user": "Terms of Use",
    "become": "Become a Psychic",
    "t_t": "Terms of Use for psychic"
  },
  "contact_us": {
    "header": "How can we help?",
    "sub-header": "If thee’s something we can do, let us know.",
    "header-form": "Submit a request",
    "email": "Your email address",
    "send": "Send",
    "subject": "Subject",
    "textarea": "Please enter the details of your request",
    "copycats": "A member of our support staff will respond as soon as possible",
    "success": "Thank you!",
    "error": "Error!!!",
    "success-message": "We appreciate that you’ve taken the time to write us. We’ll get back to you very soon. Have a great day. PsychicBook Support Team.",
    "error-message": "Your email wasn't send",
    "ok": "Ok, I understood",
    "attach": "Add file"
  },
  "info-messages": {
    "coming_IOS": "Our iOS App is almost ready to launch.",
    "coming_Android": "Our Android App is almost ready to launch.",
    "coming": "Coming soon",
    "sorry": "Stay Tuned"
  },
  "validation": {
    "required": "This field is required.",
    "email": "Please enter a valid email address."
  },
  people: {
    Michelle: {
      name: 'Michelle',
      status: 'Online',
      specialization: 'Love & Relationship',
      image: './public/images/psychic/two-section/man-1.png',
      count_stars: 5,
      ranking: '4.8',
      reviews: '17 Reviews',
      description: 'Michelle Starr prepares and analyzes horoscope to advise you regarding future trends and events.'
    },
    Harry: {
      name: 'Harry',
      status: 'Online',
      specialization: 'Live Questions',
      image: './public/images/psychic/two-section/man-2.png',
      count_stars: 5,
      ranking: '4.5',
      reviews: '25 Reviews',
      description: 'Psychic Harry Wand aims at bringing a positive outlook to your life and helps to see things from a different perspective. Harry is an inspirational and compassionate reader who often reads for clients who feel overwhelmed by their complex emotions and flowing energy.'
    },
    Wolf: {
      name: 'Wolf',
      status: 'Online',
      specialization: 'Psychic Readings',
      image: './public/images/psychic/two-section/man-3.png',
      count_stars: 5,
      ranking: '4.7',
      reviews: '49 Reviews',
      description: `I'm Clairvoyant and Clairaudient Medium. If you're ready for straightforward delivery rathe than receiving over-positive readings, welcome! I will help you find your own path through a realistic and direct approach: love life, career, all sort of issues, including financial, family, etc.`
    }
  }
};