import '../styles/index.scss';
import { arrayLanguages, browserLocale } from './localization/arrayLanguages';

require('../../node_modules/bootstrap-sass/assets/javascripts/bootstrap/dropdown.js');
require('../../node_modules/bootstrap-sass/assets/javascripts/bootstrap/modal.js');
require('../../node_modules/semantic-ui-accordion/accordion.js');
require('./localization.js');
require('./j-validation.js');
// import i18n from "../I18n/en.js";
let language = 'en';
window.localStorage.setItem('count_language', language);
const BASE_API = () => {
  switch (process.env.NODE_ENV) {
    case 'develop':
      return `https://api.dev.psychicbook.net/`;
    case 'staging':
      return `https://api.psychicbook.net/`;
    default:
      return `https://api.psychicbook.net/`;
  }
};

$(document).ready(function () {
  let doc = document.getElementsByTagName('html');
  $('#success-modal').modal({
    show: false
  });
  function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  }
  var isIOS = iOS();
  if (isIOS) {
    $(".play-store").addClass('display-none-mobile');
  } else {
    $(".app-store").addClass('display-none-mobile');
  }
  $('#error-modal').modal({
    show: false
  });
  const langT = arrayLanguages;
  language = navigator.language;
  console.log(navigator);
  $('#dropdownMenuButtonText').text(language);
  $("[data-localize]").localize(browserLocale, { language,
    callback: function(data, defaultCallback) {
      let h = document.querySelector("html"); 
      h.setAttribute("lang", language);
      defaultCallback(data);
      $("#policy-id").attr("href",`./policy.${language}.html`);
    }
  });
  const  targetLocalizeLi = $(`[data-lang*=${language}]` );
  if(targetLocalizeLi.length) {
    $(targetLocalizeLi[0]).append( `<span id="check"><svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 5L3 7L9 1" stroke="black"/>
    </svg></span>`);
  }
  $(".start-chat").click(function(e) {
    e.preventDefault();
  });
  /* $(".app-store").click(function(e) {
    e.preventDefault();
    $('#info-modal-ios').modal('show');
  }); */
  $(".target-localize").click(function() {
    const  countTargetLocalizeLi = $(`[data-lang*=${language}]` );
    if(countTargetLocalizeLi.length) {
      $(countTargetLocalizeLi[0]).find('#check').remove();
    }
    const getA = $(this).find('a')[0];
    const getLang = $(getA).attr('id');
    $('#dropdownMenuButtonText').text(getLang);
    language = getLang;
    $("[data-localize]").localize(langT[language], { language: getLang });
    const  newTargetLocalizeLi = $(`[data-lang*=${getLang}]` );
    window.localStorage.setItem('count_language', getLang);
    if(newTargetLocalizeLi.length) {
      $(newTargetLocalizeLi[0]).append( `<span id="check"><svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 5L3 7L9 1" stroke="black"/>
        </svg></span>`);
    }
    $("#policy-id").attr("href",`./policy.${language}.html`);
  });

  $(".start-chat").on("click", function(e) {
    const width = document.body.clientWidth;
    if (width < 772) {
      if (isIOS) {
        window.location = "https://apps.apple.com/app/id1525231239";
      } else {
        window.location = "https://play.google.com/store/apps/details?id=net.psychicbook.app";
      }
    } else {
      $([document.documentElement, document.body]).animate({
        scrollTop: $("#elementtoScrollToID").offset().top
    }, 2000);
    }
  });

  if ($('#idForm')) {
    console.log(window.location);
    const hostName = window.location.hostname;
    const splitHostName = hostName.split('.');
    const domain = splitHostName[splitHostName.length - 2] || 'psychicbook';
    const BrandDomain = {
      psychicbook: 'PB',
      lovewise: 'LW'

    };
    console.log(splitHostName);
    $("#idForm").validate({
      submitHandler: function(form) {
        // some other code
        // maybe disabling submit button
        // then:
        // $(form).submit();
        // e.preventDefault();
        // var form = $(this);
        var base_url = BASE_API();
        var url = `${base_url}distribution/mails/contact/support`;
        var $form = $(form);
        var formElement = $form.serializeArray();
        var data = {};
        document.getElementById('submit-btn').setAttribute("disabled", "disabled");
        formElement.forEach((item) => {
          data[item.name] = item.value;
        });
        data.brand = BrandDomain[domain] || 'PB';
        // console.log(data);
        // if(! $form.valid()) return false;
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify(data)
        }).then((res) => {
          if (res.status === 200) {
            $('#submit-btn').removeAttr('disabled');
            $('#success-modal').modal('show');
            $("#idForm").closest('form').find("input, textarea").val("");
          } else {
            $('#submit-btn').removeAttr('disabled');
            $('#error-modal').modal('show');
            $("#idForm").closest('form').find("input, textarea").val("");
          }
        }).catch((err) => {
          $('#submit-btn').removeAttr('disabled');
          $('#error-modal').modal('show');
        });
      }
     });
  }
  setNewHeight();
  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
  
  /* if(isAndroid) {
    // Do something!
    // Redirect to Android-site?
    // window.location = 'http://android.davidwalsh.name';
    const elem = document.createElement(`div`);
    elem.className='banner';
    elem.innerHTML = '<button id="btnAdd">Install our app</button> <button id="remove">X</button>';
    document.body.appendChild(elem);
    const remBut = document.getElementById('remove');
    if(!remBut) return;
    remBut.addEventListener('click', (e) => {
      e.stopPropagation();
      const bannerElem = document.getElementsByClassName('banner')[0];
      if (bannerElem) {
        bannerElem.remove();
      }
    });
  } */
});

function setNewHeight () {
  const elementMain = document.querySelector('.art');
  if (!elementMain) {
    return;
  }
  window.addEventListener('resize', () => {
    if (window.innerHeight <= 500) {
      elementMain.style.removeProperty('height');
      return;
    }
    elementMain.style.setProperty('height', `${window.innerHeight}px`);
  });
  if (window.innerHeight <= 500) {
    return;
  }
  elementMain.style.setProperty('height', `${window.innerHeight}px`);
}
