export default {
  "main": "Consejos Psíquicos",
  "sub_main_heading": "Lecturas de Tarot y Astrología",
  "Reviews": "Comentarios",
  "main_": {
    "limited_text": "Oferta limitada para nuevos clientes",
    "offer": "¡Comienza un chat gratuito ahora! <b>Pague solo 0.79 $ / min</b> cuando tenga confianza."
  },
  "two-content-block": {
    "heading": "Elige un Asesor Psíquico & Iniciar Chat Gratuito",
    "_p": ``,
    "list": {
      "_1": "Affordable",
      "_2": "Professional",
      "_3": "Anytime",
      "_4": "Anywhere",
    },
    "_sub": "Ready to start feeling better today?"
  },
  "three-content-block": {
    "heading": "¿Por qué psychicbook?",
    "_sub": "Los mejores Expertos",
    "_p": "Seleccionamos cuidadosamente a todos nuestros expertos. Toman una prueba que evalúa sus habilidades psíquicas y calificación. Le proporcionamos solo aquellos expertos que tienen muchos años de experiencia y numerosas opiniones de clientes. ¡Es un nivel completamente nuevo de consejo psíquico! ¡Solo consejos altamente personalizados y lecturas detalladas!"
  },
  "four-content-block": {
    "_sub": "Comentarios de True Advisor",
    "_p": "Cada uno de nuestros médiums psíquicos tiene su propia sección de revisión. Monitoreamos de cerca las revisiones y evitamos estafas. ¡Valoramos la opinión de cada cliente y tratamos de ayudarlo a tomar la mejor decisión!"
  },
  "five-content-block": {
    "_sub": "Atención al Cliente y Garantía de Calidad",
    "_p": "Es importante para nosotros dejar a nuestros clientes satisfechos, por lo tanto, nuestro servicio de atención al cliente funciona las 24 horas del día, los 7 días de la semana y se asegura de que su comunicación con un experto sea anónima y absolutamente segura."
  },
  "six-content-block": {
    "head": "Our Packages",
    "_sub": "Tell about yourself and book a session with psychic",
    "_p": "",
    "_text": "* Offer available to new customers ONLY. 10 minutes purchase required."
  },
  "section-last": {
    "heading": "Descargar PsyсhicBook Ahora",
    "_sub": "Text yourself a link to download PsychicBook",
    "text_qr": 'Apunta la cámara de tu teléfono',
    "or": 'O'
  },
  "footer": {
    "head": "2017 PsychicBook APP. All rights reserved.",
    "text": "<strong>Emergency Resources.</strong> If you are currently experiencing a life threatening situation or an emergency like thoughts of self harm or harming othes please use <a href=/help-en.html>these resources</a> to get immediate help or go to your nearest hospital."
  },
  "help": {
    "emergency": "Emergency Resources",
    "head": "Get help now",
    "description": "If you are currently experiencing a life threatening situation or an emergency like thoughts of self harm or harming othes please use these resources to get immediate help or go to your nearest hospital:"
  },
  "buttons": {
    "get_started": "Get Started",
    "send": "Send",
    "strtChat": "Iniciar Chat Gratuito"
  },
  "language": {
    "es": "Español",
    "en": "English",
    "pt": "Português"
  },
  "links": {
    "join_us": "Psychic? Join Us",
    "contact_us": "Contáctenos",
    "faq": "Preguntas más frecuentes",
    "privacy": "Política de Privacidad",
    "terms": "Condiciones de Uso",
    "terms-user": "Condiciones de Uso",
    "become": "Conviértete en un Psíquico",
    "t_t": "Terms of Use for psychic"
  },
  "contact_us": {
    "header": "How can we help?",
    "sub-header": "If thee’s something we can do, let us know.",
    "header-form": "Submit a request",
    "email": "Your email address",
    "send": "Send",
    "subject": "Subject",
    "textarea": "Please enter the details of your request",
    "copycats": "A member of our support staff will respond as soon as possible",
    "success": "Thank you!",
    "error": "Error!!!",
    "success-message": "We appreciate that you’ve taken the time to write us. We’ll get back to you very soon. Have a great day. PsychicBook Support Team.",
    "error-message": "Your email wasn't send",
    "ok": "Ok, I understood",
    "attach": "Add file"
  },
  "info-messages": {
    "coming_IOS": "Our iOS App is almost ready to launch.",
    "coming_Android": "Our Android App is almost ready to launch.",
    "coming": "Coming soon",
    "sorry": "Stay Tuned"
  },
  "validation": {
    "required": "This field is required.",
    "email": "Please enter a valid email address."
  },
  people: {
    Michelle: {
      name: 'Michelle',
      status: 'En línea',
      specialization: 'Love & Relationship',
      image: './public/images/psychic/two-section/man-1.png',
      count_stars: 5,
      ranking: '4.8',
      reviews: '17 Comentarios',
      description: 'Michelle Starr prepara y analiza el horóscopo para asesorarte sobre tendencias y eventos futuros.'
    },
    Harry: {
      name: 'Harry',
      status: 'En línea',
      specialization: 'Live Questions',
      image: './public/images/psychic/two-section/man-2.png',
      count_stars: 5,
      ranking: '4.5',
      reviews: '25 Comentarios',
      description: 'Psychic Harry Wand tiene como objetivo traer una perspectiva positiva a su vida y ayuda a ver las cosas desde una perspectiva diferente. Harry es un lector inspirador y compasivo que a menudo lee para clientes que se sienten abrumados por sus emociones complejas y su energía que fluye.'
    },
    Wolf: {
      name: 'Wolf',
      status: 'En línea',
      specialization: 'Psychic Readings',
      image: './public/images/psychic/two-section/man-3.png',
      count_stars: 5,
      ranking: '4.7',
      reviews: '49 Comentarios',
      description: `Soy Clarividente y Medio Clariaudiente. Si está listo para una entrega directa que para recibir lecturas demasiado positivas, ¡bienvenido! Te ayudaré a encontrar tu propio camino a través de un enfoque realista y directo: vida amorosa, carrera, todo tipo de cuestiones, incluidas las financieras, familiares, etc.`
    }
  }
};